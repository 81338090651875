var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-modal",
    {
      attrs: {
        title: _vm.modalConfig.title,
        visible: _vm.modalConfig.visible,
        width: _vm.modalConfig.width || "1000px",
        destroyOnClose: true,
        maskClosable: false,
      },
      on: { cancel: _vm.close },
    },
    [
      _c(
        "el-scrollbar",
        {
          staticClass: "dialog-style",
          attrs: {
            "wrap-class": "content-scrollbar",
            native: false,
            "view-class": "content-container",
            noresize: false,
            tag: "div",
          },
        },
        [
          _c("upload-table", {
            ref: "fileTable",
            attrs: { disabled: _vm.disabled },
            on: { onGetFileList: _vm.onGetFileList },
          }),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [_c("el-button", { on: { click: _vm.close } }, [_vm._v("关闭")])],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }