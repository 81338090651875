<template>
  <div>
    <vxe-toolbar>
      <template v-slot:buttons>
        <vxe-button @click="addLine(-1)" v-if="!disabled">新增行</vxe-button>
        <vxe-button @click="copyLine()" v-if="!disabled">复制行</vxe-button>
        <vxe-button @click="apportionBudget()" v-if="!disabled">分摊</vxe-button>
        <vxe-button v-if="isFileUpload" @click="upload()">附件</vxe-button>
        <vxe-button @click="deleteTable()" v-if="!disabled">清空</vxe-button>
      </template>
    </vxe-toolbar>
    <vxe-table
      ref="fineTable"
      resizable
      show-overflow
      :data="fineList"
      :radio-config="{ highlight: true }"
      :edit-rules="validRules"
      @radio-change="radioChangeEvent"
      :edit-config="{ trigger: 'click', mode: 'row', showIcon: false, immediate: true }"
      @edit-actived="editActivedEvent"
    >
      <vxe-table-column type="radio" width="36" v-if="!disabled"> </vxe-table-column>
      <vxe-table-column
        v-for="item in columns"
        :key="item.field"
        :field="item.field"
        :title="item.title"
        :edit-render="editRender(item)"
        :min-width="countWidthFn(item)"
        :formatter="filterMethod"
      >
        <template v-if="item.render" v-slot="{ row }">
          <span>{{ item.render && item.render(row) }}</span>
        </template>
      </vxe-table-column>

      <vxe-table-column title="操作" width="100" show-overflow v-if="!disabled">
        <template v-slot="{ row }">
          <vxe-button type="text" icon="el-icon-delete" @click="removeEvent(row)"></vxe-button>
        </template>
      </vxe-table-column>
    </vxe-table>
    <upload-modal ref="uploadModal" @onGetFileList="onGetFileList" :disabled="disabled" />
  </div>
</template>

<script>
import UploadModal from '../../../../../../../../components/upload_modal/upload.vue';
import request from '../../../../../../../../utils/request';
import defaultConfig from './data';

export default {
  name: 'fineTable',
  components: {
    'upload-modal': UploadModal,
  },
  props: {
    disabled: Boolean,
    value: Object,
    categoriesCode: String, // 大类
    functionCode: String, // 表单模版code
    categoriesName: String,
    orgCode: String, // 组织
    orgName: String,
    beginDate: String, // 活动开始时间
    endDate: String, // 活动结束时间
    controlIds: Array, // 费用编码集合
    // column: Array, // 表头集合
    actType: String, // 活动类型
    fileList: Array, // 文件列表
    isFileUpload: {
      type: Boolean,
      default: true,
    }, // 是否需要文件上传
  },
  watch: {
    value() {
      this.fineList = this.value.data;
    },
    // column(v) {
    //   if (!v) {
    //     this.getColumns();
    //   }
    // },
    categoriesCode(n) {
      if (n !== '') {
        this.getFineList();
      } else if (n === '') {
        this.deleteTable();
      }
    },
    orgCode(n) {
      if (n) {
        this.getCustomerList();
      }
    },
    actType() {},
  },
  data() {
    return {
      selectRow: null,
      activeRow: null,
      loading: false,
      fineList: this.value.data && this.value.data.length ? this.value.data : [], // 当前细类数据
      fineDataList: [], // 全部细类数据
      orgList: [], // 组织列表
      customerList: [], // 客户列表
      payTypeList: [], // 支付方式
      subTerminalList: [], // 门店列表
      materialList: [], // 物料列表
      materialUnitList: [], // 物料单位
      columns: [], // 展示配置的表头
      fields: [],
      validRules: {}, // 配置校验规则
      productLevelList: [], // 产品层级
      productList: [], // 产品列表
    };
  },
  inject: ['$MODALFORM'],
  created() {
    this.init();
    this.getProductLevelList();
  },
  methods: {
    countWidthFn(item) {
      if (
        [
          'giftCodes',
          'normalProductCodes',
          'replenishmentProductCodes',
          'executionProductCodes',
        ].includes(item.field)
      ) {
        return 260;
      }
      return 130;
    },
    filterMethod({ cellValue, row, column }) {
      let text = cellValue;
      switch (column.property) {
        // case 'fineCode': {
        //   text = row.fineCode ? `${row.fineCode}/${row.fineName}` : '';
        //   break;
        // }
        // case 'orgCode': {
        //   text = row.orgCode ? `${row.orgCode}/${row.orgName}` : '';
        //   break;
        // }
        // case 'customerCode': {
        //   text = row.customerCode ? `${row.customerCode}/${row.customerName}` : '';
        //   break;
        // }
        // case 'materialCode': {
        //   text = row.materialCode ? `${row.materialCode}/${row.materialName}` : '';
        //   break;
        // }
        // case 'terminalCode': {
        //   text = row.terminalCode ? `${row.terminalCode}/${row.terminalName}` : '';
        //   break;
        // }
        // case 'materialUnit': {
        //   text = row.materialUnitName;
        //   break;
        // }
        // case 'payType': {
        //   text = row.payTypeName;
        //   break;
        // }
        case 'giftCodes': {
          console.log(row);
          text = (row.giftProductList || []).map((v) => v.productName).join(',') || '';
          break;
        }
        case 'normalProductCodes': {
          text = (row.normalProductList || []).map((v) => v.productName).join(',') || '';
          break;
        }
        case 'replenishmentProductCodes': {
          text = (row.replenishmentProductList || []).map((v) => v.productName).join(',') || '';
          break;
        }
        case 'executionProductCodes': {
          text = (row.executionProductList || []).map((v) => v.productName).join(',') || '';
          break;
        }
        default: {
          text = cellValue;
          break;
        }
      }
      return text;
    },

    validatorOrg({ row, cellValue }) {
      const rows = this.getOption(this.fineDataList, row.fineCode);
      if (
        rows.activityReleaseNeed
        && rows.activityReleaseNeed === 'department'
        && cellValue === ''
      ) {
        return new Error('请选择组织！');
      }
    },
    validatorCustomer({ row, cellValue }) {
      const rows = this.getOption(this.fineDataList, row.fineCode);
      if (
        rows.activityReleaseNeed
        && (rows.activityReleaseNeed === 'customer' || rows.activityReleaseNeed === 'terminal')
        && cellValue === ''
      ) {
        return new Error('请选择客户！');
      }
    },
    validatorTerminal({ row, cellValue }) {
      const rows = this.getOption(this.fineDataList, row.fineCode);
      if (rows.activityReleaseNeed && rows.activityReleaseNeed === 'terminal' && cellValue === '') {
        return new Error('请选择门店！');
      }
    },
    async validate() {
      const errMapList = await this.$refs.fineTable.fullValidate().catch((errMap) => errMap);
      if (errMapList) {
        const msgList = [];
        Object.values(errMapList).forEach((errList) => {
          errList.forEach((params) => {
            const { rowIndex, column, rules } = params;
            rules.forEach((rule) => {
              msgList.push(`第 ${rowIndex + 1} 行 ${column.title} 校验错误：${rule.message}`);
            });
          });
        });

        this.$message({
          type: 'error',
          message: msgList[0],
        });
      }
      return errMapList;
    },
    // 配置校验规则
    setValidRules(data) {
      const rules = {};
      data.forEach((item) => {
        const {
          type, title, field, required,
        } = item;

        rules[field] = [
          {
            required: typeof required === 'undefined' ? false : required,
            message: type === 'select' ? `请选择${title}` : `请输入${title}`,
          },
        ];

        if (field === 'orgCode') {
          rules[field].push({
            validator: this.validatorOrg,
          });
        }
        if (field === 'customerCode') {
          rules[field].push({
            validator: this.validatorCustomer,
          });
        }

        if (field === 'terminalCode') {
          rules[field].push({
            validator: this.validatorTerminal,
          });
        }
      });
      this.validRules = rules;
    },
    // 配置自定义组件
    editRender(item) {
      const { type, title, field } = item;
      let tmp = {
        name: type,
      };
      if (type && !this.disabled) {
        tmp = {
          name: type,
          props: {
            placeholder: `请输入${title}`,
            transfer: true,
          },
          events: {
            change: ({ row }) => {
              this.changeRow(row, field);
            },
          },
        };

        if (['num', 'applyAmount', 'forecastSalesAmount'].includes(field)) {
          tmp = {
            ...tmp,
            name: '$input',
            props: {
              ...tmp.props,
              type: 'number',
              min: '0',
            },
          };
        }

        if (type === 'select') {
          tmp = {
            ...tmp,
            name: 'ElSelect',
            props: {
              placeholder: `请选择${title}`,
              transfer: true,
              filterable: true,
              clearable: true,
              remote: true,
              reserveKeyword: true,
              remoteMethod: (query) => this.remoteMethod(field, query),
              loading: this.loading,
            },
          };
          // 绑定数组
          switch (field) {
            case 'fineCode': {
              tmp.options = this.fineDataList;
              break;
            }
            case 'orgCode': {
              tmp.options = this.orgList;
              break;
            }
            case 'customerCode': {
              tmp.options = this.customerList;
              break;
            }
            case 'terminalCode': {
              tmp.options = this.subTerminalList;
              break;
            }
            case 'materialCode': {
              tmp.options = this.materialList;
              break;
            }
            case 'materialUnit': {
              tmp.options = this.materialUnitList;
              tmp.props.remote = false;
              tmp.props.reserveKeyword = false;
              break;
            }
            case 'payType': {
              tmp.options = this.payTypeList;
              tmp.props.remote = false;
              tmp.props.reserveKeyword = false;
              break;
            }
            case 'productLevelCode': {
              tmp.options = this.productLevelList;
              tmp.props.remote = false;
              tmp.props.reserveKeyword = false;
              break;
            }
            case 'giftCodes': {
              tmp.options = this.productList;
              tmp.props.multiple = true;
              tmp.props.remote = false;
              tmp.props.reserveKeyword = false;
              tmp.props.collapseTags = true;
              tmp.events = {
                ...tmp.events,
                focus: ({ row }) => {
                  this.getProductList(field, row);
                },
              };
              break;
            }
            case 'normalProductCodes': {
              tmp.options = this.productList;
              tmp.props.multiple = true;
              tmp.props.remote = false;
              tmp.props.reserveKeyword = false;
              tmp.props.collapseTags = true;
              tmp.events = {
                ...tmp.events,
                focus: ({ row }) => {
                  this.getProductList(field, row);
                },
              };
              break;
            }
            case 'replenishmentProductCodes': {
              tmp.options = this.productList;
              tmp.props.multiple = true;
              tmp.props.remote = false;
              tmp.props.reserveKeyword = false;
              tmp.props.collapseTags = true;
              tmp.events = {
                ...tmp.events,
                focus: ({ row }) => {
                  this.getProductList(field, row);
                },
              };
              break;
            }
            case 'executionProductCodes': {
              tmp.options = this.productList;
              tmp.props.multiple = true;
              tmp.props.remote = false;
              tmp.props.reserveKeyword = false;
              tmp.props.collapseTags = true;
              tmp.events = {
                ...tmp.events,
                focus: ({ row }) => {
                  this.getProductList(field, row);
                },
              };
              break;
            }
            default: {
              break;
            }
          }
        }
      }
      return tmp;
    },
    // 远程
    async remoteMethod(field, query) {
      this.loading = true;
      switch (field) {
        case 'fineCode': {
          await this.getFineList(query);
          this.loading = false;
          break;
        }
        case 'orgCode': {
          await this.getOrgList(query);
          this.loading = false;
          break;
        }
        case 'customerCode': {
          await this.getCustomerList(this.activeRow, query);
          this.loading = false;
          break;
        }
        case 'terminalCode': {
          await this.getSubTerminalList(this.activeRow, query);
          this.loading = false;
          break;
        }
        default: {
          this.loading = false;
          break;
        }
      }
    },
    // 处理表头配置信息
    getColumns() {
      const list = this.column && this.column.length ? this.column : defaultConfig.column;
      this.fields = list.map((item) => item.field);
      const visibleList = list.filter((item) => item.visible);
      this.setValidRules(visibleList);
      this.columns = visibleList;
    },
    setValue() {
      const rows = this.$refs.fineTable.afterFullData;
      this.value.data = rows;
      this.fineList = rows;
      this.$emit('change', rows);
    },
    deleteTable() {
      this.$refs.fineTable.remove();
      this.value.data = [];
      this.fineList = [];
      this.selectRow = null;
      this.$emit('change', []);
    },
    radioChangeEvent({ row }) {
      this.selectRow = row;
    },
    clearRadioRowEvent() {
      this.selectRow = null;
      this.$refs.xTable1.clearRadioRow();
    },
    async copyLine() {
      if (!this.selectRow) {
        this.$message({
          type: 'error',
          message: '请先选一条数据',
        });
      } else {
        const row = { ...this.selectRow, feeShareGroupId: '', feeDateStr: '' };
        if (row.actDetailCode) {
          delete row.actDetailCode;
        }
        if (row.id) {
          delete row.id;
        }
        await this.$refs.fineTable.insertAt(row, this.selectRow);
        this.setValue();
      }
    },
    async apportionBudget() {
      const { beginDate, endDate } = this;
      if (!this.selectRow) {
        this.$message({
          type: 'error',
          message: '请先选一条数据',
        });
      } else if (this.selectRow && this.selectRow.feeShareGroupId !== '') {
        this.$message({
          type: 'error',
          message: '当前活动明细不能再次进行分摊！',
        });
      } else if (this.selectRow && this.selectRow.applyAmount === '') {
        this.$message({
          type: 'error',
          message: '请先输入费用金额！',
        });
      } else if (beginDate === '') {
        this.$message({
          type: 'error',
          message: '请先选活动时间！',
        });
      } else {
        const { applyAmount, feeShareGroupId } = this.selectRow;
        const params = {
          feeShareAmount: Number(applyAmount),
          executeBeginDate: beginDate,
          executeEndDate: endDate,
        };

        if (feeShareGroupId) {
          params.feeShareGroupId = feeShareGroupId;
        }

        const res = await request.post('/tpm/tpmActController/actDetailFeeShare', params);
        if (res.success) {
          this.replaceRow(res.result, this.selectRow);
        }
      }
    },
    replaceRow(data, row) {
      if (data && data.length === 0) return;
      const tableData = this.fineList;
      const index = tableData.findIndex((item) => item._XID === row._XID);
      if (index > -1) {
        const tmp = [];
        data.forEach((item, m) => {
          const _row = {
            ...row,
            feeShareGroupId: item.feeShareGroupId,
            feeDateStr: item.feeDateStr,
            applyAmount: item.feeShareAmount,
          };

          if (_row.id && m !== 0) {
            delete _row.id;
            delete _row.actDetailCode;
          }
          tmp.push(_row);
        });
        this.$refs.fineTable.insertAt(tmp, row);
        this.$refs.fineTable.remove(row);
        this.setValue();
        this.selectRow = null;
      }
    },
    upload() {
      this.$refs.uploadModal.openModal(this.value.fileList);
    },
    onGetFileList(data) {
      this.value.fileList = data;
      this.$emit('onFileChange', data);
    },
    async addLine(e) {
      if (
        !this.$MODALFORM.fApi.getValue('categoriesCode')
        && this.$MODALFORM.actType !== 'project'
      ) {
        return this.$message.error('请先选择活动大类');
      }
      const row = {
        fineCode: '',
        orgCode: this.orgCode ? this.orgCode : '',
        orgName: this.orgName ? this.orgName : '',
        customerCode: '',
        terminalCode: '',
        forecastSalesAmount: '',
        applyAmount: '',
        payType: '',
        remarks: '',
        feeDateStr: '',
        feeShareGroupId: '',
        fineName: '',
        customerName: '',
        terminalName: '',
        payTypeName: '',
        categoriesCode: this.categoriesCode,
        categoriesName: this.categoriesName,
        isShareToProduct: '',

        materialCode: '',
        materialName: '',
        materialPrice: '',
        materialUnit: '',
        materialUnitName: '',
        num: '',
        giftProductList: [],
        normalProductList: [],
        replenishmentProductList: [],
        executionProductList: [],
      };

      const { row: newRow } = await this.$refs.fineTable.insertAt(row, e);
      await this.$refs.fineTable.setActiveCell(newRow, 'fineCode');
      this.setValue();
    },
    getOption(data, value) {
      let row = {};
      const index = data.findIndex((item) => item.value === value);
      if (index > -1) {
        row = data[index];
      }
      return row;
    },
    updatePayTypeList(row) {
      const { payTypes } = this.getOption(this.fineDataList, row.fineCode);
      const data = [];
      if (payTypes && payTypes.length) {
        payTypes.forEach((item) => {
          data.push({
            label: item.dictKey,
            value: item.dictValue,
          });
        });
      }
      this.payTypeList = data;
    },
    async editActivedEvent({ row, column }) {
      this.activeRow = row;
      // 获取支付方式
      if (column.property === 'payType') {
        await this.updatePayTypeList(row);
      }
      // 获取客户列表
      if (column.property === 'customerCode') {
        await this.getCustomerList(row);
      }
      // 获取门店列表
      if (column.property === 'terminalCode') {
        await this.getSubTerminalList(row);
      }
      // 获取物料单位
      if (column.property === 'materialUnit') {
        await this.updateMaterialUnitList(row);
      }
    },
    updateRow(row, otherRow) {
      const list = this.fineList;
      console.log('before  this.fineList>>>', this.fineList);
      const index = list.findIndex((item) => item._XID === row._XID);
      if (index > -1) {
        list[index] = {
          ...row,
          ...otherRow,
        };
      }
      this.fineList = list.concat([]);
      console.log('after  this.fineList>>>', this.fineList);
      this.value.data = this.fineList;
      this.$emit('change', this.fineList);
    },
    async validEvent() {
      await this.$refs.fineTable.fullValidate().catch((errMap) => errMap);
    },
    // 计算单箱费用
    countFn(row) {
      const rows = [];
      const { num, applyAmount } = row;
      let countNum = 0;

      if (num && applyAmount) {
        countNum = (Number(applyAmount) / Number(num)).toFixed(2);
      }

      this.value.data.forEach((item) => {
        const v = item;
        if (v._XID === row._XID) {
          v.ext1 = countNum;
        }
        rows.push(v);
      });

      this.$emit('change', rows);
    },
    changeRow(row, field) {
      let _item = {};
      switch (field) {
        case 'applyAmount': {
          this.countFn(row);
          break;
        }
        case 'num': {
          this.countFn(row);
          break;
        }
        case 'fineCode': {
          const rows = this.getOption(this.fineDataList, row.fineCode);
          this.updateRow(row, {
            fineName: rows.fineName,
            isShareToProduct: rows.isShareToProduct,
            payType: '',
            payTypeName: '',
          });
          this.updatePayTypeList(rows);
          this.$nextTick(() => {
            this.validEvent();
          });
          break;
        }
        case 'orgCode': {
          const rows = this.getOption(this.orgList, row.orgCode);
          this.updateRow(row, {
            orgName: rows.orgName,
            customerCode: '',
            customerName: '',
            terminalCode: '',
            terminalName: '',
          });
          break;
        }
        case 'customerCode': {
          const rows = this.getOption(this.customerList, row.customerCode);
          this.updateRow(row, {
            customerName: rows.customerName,
            terminalCode: '',
            terminalName: '',
          });
          break;
        }
        case 'terminalCode': {
          const rows = this.getOption(this.subTerminalList, row.terminalCode);
          this.updateRow(row, { terminalName: rows.terminalName });
          break;
        }
        case 'payType': {
          const rows = this.getOption(this.payTypeList, row.payType);
          this.updateRow(row, { payTypeName: rows.label });
          break;
        }
        case 'materialCode': {
          const rows = this.getOption(this.materialList, row.materialCode);
          this.updateRow(row, {
            materialName: rows.materialName,
            materialUnit: '',
            materialUnitName: '',
            materialPrice: '',
            applyAmount: '',
          });
          this.updateMaterialUnitList(rows);
          break;
        }
        case 'materialUnit': {
          const rows = this.getOption(this.materialUnitList, row.materialUnit);
          this.updateRow(row, {
            materialUnitName: rows.label,
            materialPrice: rows.costPrice,
            applyAmount: Number(row.num) * rows.costPrice,
          });
          break;
        }
        case 'productLevelCode':
          this.updateRow(row, {
            replenishmentProductCodes: '',
            replenishmentProductList: [],
          });
          break;
        case 'giftCodes':
          _item = this.productList.filter((v) => row.giftCodes.includes(v.productCode));

          this.updateRow(row, {
            giftProductList: _item.map((v) => {
              const { id, ...params } = v;
              return params;
            }),
          });
          break;
        case 'normalProductCodes':
          _item = this.productList.filter((v) => row.normalProductCodes.includes(v.productCode));

          this.updateRow(row, {
            normalProductList: _item.map((v) => {
              const { id, ...params } = v;
              return params;
            }),
          });
          break;
        case 'replenishmentProductCodes':
          _item = this.productList.filter((v) => row.replenishmentProductCodes.includes(v.productCode));

          this.updateRow(row, {
            replenishmentProductList: _item.map((v) => {
              const { id, ...params } = v;
              return params;
            }),
          });
          break;
        case 'executionProductCodes':
          _item = this.productList.filter((v) => row.executionProductCodes.includes(v.productCode));

          this.updateRow(row, {
            executionProductList: _item.map((v) => {
              const { id, ...params } = v;
              return params;
            }),
          });
          break;
        default:
          break;
      }
    },
    removeEvent(row) {
      this.$refs.fineTable.remove(row);
      this.setValue();
    },
    // 组织
    async getOrgList(query) {
      const param = {
        controlIds: this.controlIds,
      };
      // 模糊查询
      if (query !== '') {
        param.orgCodeOrName = query;
      }
      // 组织
      const orgList = await this.getSelectData(
        '/tpm/tpmActController/getOrgInfoByControlIds',
        param,
        {
          label: 'orgName',
          value: 'orgCode',
        },
      );
      this.orgList = orgList;
    },
    // 客户
    async getCustomerList(row, query) {
      if (row && row.orgCode) {
        const param = {
          orgCode: row.orgCode,
        };

        // 模糊查询
        if (query !== '') {
          param.customerCodeOrName = query;
        }

        const customerList = await this.getSelectData(
          '/tpm/tpmActController/getCustomerMsgByOrgCodes',
          param,
          {
            label: 'customerName',
            value: 'customerCode',
          },
        );
        this.customerList = customerList;
        this.subTerminalList = [];
      } else {
        this.customerList = [];
        this.subTerminalList = [];
      }
    },
    // 更新物料单位
    updateMaterialUnitList(row) {
      const { mdmMaterialUnitRespVos } = this.getOption(this.materialList, row.materialCode);
      const data = [];
      if (mdmMaterialUnitRespVos && mdmMaterialUnitRespVos.length) {
        mdmMaterialUnitRespVos.forEach((item) => {
          data.push({
            ...item,
            label: item.unitName,
            value: item.unit,
          });
        });
      }
      this.materialUnitList = data;
    },
    // 物料
    async getMaterialList(query) {
      const param = {
        enableStatus: '009',
      };
      // 模糊查询
      if (query !== '') {
        param.materialCodeOrName = query;
      }
      const materialList = await this.getSelectData(
        '/tpm/tpmActController/getMaterialList',
        param,
        {
          label: 'materialName',
          value: 'materialCode',
        },
        true,
      );

      this.materialList = materialList;
    },
    // 门店
    async getSubTerminalList(row, query) {
      if (row && row.customerCode) {
        const param = {
          customerCodeList: [row.customerCode],
        };

        // 模糊查询
        if (query !== '') {
          param.codeOrName = query;
        }

        const list = await this.getSelectData(
          '/mdm/mdmTerminalController/customerTerminalList',
          param,
          {
            label: 'terminalName',
            value: 'terminalCode',
          },
        );
        this.subTerminalList = list;
      } else {
        this.subTerminalList = [];
      }
    },
    // 细类
    async getFineList(query) {
      const { categoriesCode } = this;
      if (categoriesCode) {
        const param = {
          categoriesCode,
          actType: this.actType,
        };
        // 模糊查询
        if (query !== '') {
          param.fineCodeOrName = query;
        }

        const list = await this.getSelectData(
          '/tpm/tpmActController/getCategoryFinesByCategoryCode',
          param,
          {
            label: 'fineName',
            value: 'fineCode',
          },
        );

        this.fineDataList = list;
      }
    },
    // 产品层级
    async getProductLevelList(query) {
      const param = {
        enableStatus: '009',
      };
      // 模糊查询
      if (query) {
        param.productName = query;
      }

      const list = await this.getSelectData(
        '/mdm/mdmProductLevelController/productLevelSelectList',
        param,
        {
          label: 'productLevelName',
          value: 'productLevelCode',
        },
      );

      this.productLevelList = list;
    },
    // 产品
    async getProductList(field, row) {
      this.loading = true;
      const param = {
        enableStatus: '009',
      };

      if (field === 'replenishmentProductCodes') {
        param.productLevelCode = row.productLevelCode || '';
      }

      // 模糊查询
      // if (query) {
      //   param.productName = query;
      // }

      const list = await this.getSelectData('/mdm/mdmProductController/productSelectList', param, {
        label: 'productName',
        value: 'productCode',
      });

      this.productList = list;
      this.loading = false;
    },

    async getSelectData(url, params, optionsKey) {
      const res = await request.post(url, params);
      const list = res.result || [];
      if (res.success) {
        list.forEach((item, index) => {
          list[index].label = item[optionsKey.label];
          list[index].value = item[optionsKey.value];
        });
      }
      return list;
    },
    // 获取细类列表
    async init() {
      const { functionCode } = this;
      if (functionCode) {
        const res = await request.post('/mdm/mdmcolumnconfig/list_condition', {
          functionCode,
          parentCode: 'CRM20201126000000093',
        });
        this.column = res.result || [];
      }

      this.getColumns();
      // 加载细类列表
      await this.getFineList();
      // 项目活动时，加载组织列表
      if (this.actType === 'project') {
        await this.getOrgList();
      }
      // 加载客户
      if (
        this.actType === 'stable_charge'
        || this.actType === 'practicality_charge'
        || this.actType === 'department_receive'
        || this.actType === 'department_charge'
      ) {
        await this.getCustomerList();
      }
      // 加载物料
      if (this.actType === 'practicality_charge' || this.actType === 'department_receive') {
        await this.getMaterialList();
      }

      this.getProductList();
    },
  },
};
</script>

<style lang="less" scoped></style>
