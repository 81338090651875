<template>
  <div v-if="show">
    <vxe-toolbar v-if="!disabled">
        <template v-slot:buttons>
        <vxe-button @click="addLine()">新增行</vxe-button>
        <vxe-button @click="deleteTable()">清空</vxe-button>
        </template>
    </vxe-toolbar>

    <vxe-table
        ref="fineTable"
        :align="allAlign"
        :data="productList"
        :edit-rules="validRules"
        :edit-config="{trigger: 'click', mode: 'row', showIcon:false, immediate: true}"
    >
        <vxe-table-column
            field="productAndLevelCode"
            title="产品"
            :edit-render="!this.disabled && {
                    name: 'ElSelect',
                    options: this.productOptionList,
                    props: {
                    placeholder: '请选择产品',
                    transfer: true,
                    filterable: true,
                    remote: true,
                    reserveKeyword: true,
                    remoteMethod: this.getProductList,
                    loading: this.loading,
                    },
                    events: {
                        change: ({ row }) => {
                          this.changeRow(row,'productAndLevelCode')
                        }
                    }
                }">
        </vxe-table-column>
<!--        <vxe-table-column-->
<!--            field="productAndLevelName"-->
<!--            title="产品名称"-->
<!--        >-->
<!--        </vxe-table-column>-->
        <vxe-table-column
            field="shareRatio"
            title="产品比例（%）"
            required
            :edit-render="!this.disabled && {
                    name: '$input',
                    props: { placeholder: '请输入产品比例', type:'number'},
                    events: {
                        change: ({ row }) => {
                          this.changeRow(row,'shareRatio')
                        }
                    }
                }">
        </vxe-table-column>
        <vxe-table-column
            field="remarks"
            title="备注"
            :edit-render="!this.disabled && {
                    name: '$input',
                    props: { placeholder: '请输入备注' },
                    events: {
                        change: ({ row }) => {
                          this.changeRow(row, 'remarks')
                        }
                    }
                }">
        </vxe-table-column>
        <vxe-table-column title="操作" width="100" show-overflow v-if="!this.disabled">
        <template v-slot="{ row }">
            <vxe-button type="text" icon="el-icon-delete" @click="removeEvent(row)"></vxe-button>
        </template>
        </vxe-table-column>
    </vxe-table>
  </div>
</template>

<script>
// 公用的产品分摊表格
import request from '../../../../../../../utils/request';

export default {
  name: 'ProductRelation',
  props: {
    value: Object,
    show: Boolean,
    disabled: Boolean,
    categoriesCode: String,
  },
  watch: {
    value() {
      this.productList = (this.value && this.value.data) || [];
    },
    show() {},
    categoriesCode() {},
  },
  data() {
    return {
      allAlign: null,
      loading: false,
      productList: this.value && this.value.data && this.value.data.length ? this.value.data : [], // 当前关联细类数据
      productOptionList: [], // 全部细类数据
      validRules: {
        productAndLevelCode: [
          {
            required: true,
            message: '请选择产品！',
          },
        ],
        shareRatio: [
          {
            required: true,
            message: '请输入产品比例！',
          },
          {
            required: true,
            validator: this.validatorRatio,
          },
        ],
      },
    };
  },
  created() {
    this.initPage();
  },
  methods: {
    validatorRatio({ cellValue }) {
      console.log(cellValue);
      const rows = this.$refs.fineTable.afterFullData;
      let sum = 0;
      rows.map((item) => sum += Number(item.shareRatio));

      if (sum !== 100) {
        return new Error('分摊的产品比例和必须是100！');
      }
    },
    setValue() {
      const rows = this.$refs.fineTable.afterFullData;
      this.value.data = rows;
      this.productList = rows;
    },
    deleteTable() {
      this.$refs.fineTable.remove();
      this.value.data = [];
    },
    async addLine() {
      const row = {
        categoriesCode: typeof this.categoriesCode === 'undefined' ? '' : this.categoriesCode,
        productAndLevelCode: '',
        productAndLevelName: '',
        shareRatio: '',
        remarks: '',
        productShareType: '',
      };

      const { row: newRow } = await this.$refs.fineTable.insertAt(row);
      await this.$refs.fineTable.setActiveCell(newRow, 'productAndLevelCode');
      this.setValue();
    },
    getOption(data, value) {
      let row = {};
      const index = data.findIndex((item) => item.value === value);
      if (index > -1) {
        row = data[index];
      }
      return row;
    },
    updateRow(row, otherRow) {
      const list = this.productList;
      const index = list.findIndex((item) => item._XID === row._XID);
      if (index > -1) {
        list[index] = {
          ...row,
          ...otherRow,
        };
      }
      this.productList = list.concat([]);
      this.value.data = this.productList;
    },
    changeRow(row, field) {
      switch (field) {
        case 'productAndLevelCode': {
          const option = this.getOption(this.productOptionList, row.productAndLevelCode);
          this.updateRow(row, { productAndLevelName: option.label, productShareType: option.type });
          break;
        }
        case 'shareRatio': {
          break;
        }
        default:
          break;
      }
    },
    removeEvent(row) {
      this.$refs.fineTable.remove(row);
      this.setValue();
    },
    initPage() {
      this.getProductList();
    },
    async getProductList(query) {
      this.loading = true;
      const list = await this.getSelectData('/mdm/mdmProductController/findProductAndProductLevel', {
        codeOrName: query,
        pageSize: 50,
      }, {
        label: 'productAndLevelName',
        value: 'productAndLevelCode',
      });
      this.loading = false;
      this.productOptionList = list;
    },
    async getSelectData(url, params, optionsKey, concatLabelValue = true) {
      const res = await request.post(url, params);
      const list = res.result || [];
      if (res.success) {
        list.forEach((item, index) => {
          list[index].label = item[optionsKey.label];
          list[index].value = item[optionsKey.value];
        });
      }
      return list;
    },
  },
};
</script>

<style lang="less" scoped>

</style>
