/*
 * @Author: chenlei
 * @Date: 2020-12-14 10:36:28
 * @LastEditors: chenlei
 * @LastEditTime: 2020-12-16 18:08:05
 * @Description:
 */
export default {
  column: [
    {
      type: 'select',
      field: 'fineCode',
      title: '活动细类',
      required: true,
      width: null,
      visible: true,
    },
    {
      type: 'select',
      field: 'fineName',
      title: '活动细类',
      required: true,
      width: null,
      visible: false,
    },
    {
      type: 'select',
      field: 'orgCode',
      title: '企业组织',
      required: true,
      visible: true,
      width: null,
    },
    {
      type: 'select',
      field: 'orgName',
      title: '企业组织',
      required: true,
      visible: false,
      width: null,
    },
    {
      type: 'select',
      field: 'customerCode',
      title: '客户',
      required: true,
      visible: true,
      width: null,
    },
    {
      type: 'select',
      field: 'customerName',
      title: '客户',
      required: true,
      visible: false,
      width: null,
    },
    {
      type: 'select',
      field: 'terminalCode',
      title: '门店',
      required: true,
      visible: true,
      width: null,
    },
    {
      type: 'select',
      field: 'terminalName',
      title: '门店',
      required: true,
      visible: false,
      width: null,
    },
    {
      type: 'input',
      field: 'forecastSalesAmount',
      title: '预估销售额',
      required: false,
      visible: true,
      width: null,
    },
    {
      type: 'input',
      field: 'applyAmount',
      title: '费用申请金额',
      required: true,
      visible: true,
      width: null,
    },
    {
      type: 'select',
      field: 'payType',
      title: '支付方式',
      required: true,
      visible: true,
      width: null,
    },
    {
      type: 'select',
      field: 'payTypeName',
      title: '支付方式',
      required: true,
      visible: false,
      width: null,
    },
    {
      field: 'feeDateStr',
      title: '费用所属年月',
      required: true,
      validate: null,
      visible: true,
      width: null,
    },
    {
      type: 'input',
      field: 'remarks',
      title: '备注',
      required: false,
      validate: null,
      visible: true,
      width: null,
    },
  ],
};
