var render = function () {
  var this$1 = this
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.show
    ? _c(
        "div",
        [
          !_vm.disabled
            ? _c("vxe-toolbar", {
                scopedSlots: _vm._u(
                  [
                    {
                      key: "buttons",
                      fn: function () {
                        return [
                          _c(
                            "vxe-button",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.addLine()
                                },
                              },
                            },
                            [_vm._v("新增行")]
                          ),
                          _c(
                            "vxe-button",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.deleteTable()
                                },
                              },
                            },
                            [_vm._v("清空")]
                          ),
                        ]
                      },
                      proxy: true,
                    },
                  ],
                  null,
                  false,
                  240087977
                ),
              })
            : _vm._e(),
          _c(
            "vxe-table",
            {
              ref: "fineTable",
              attrs: {
                align: _vm.allAlign,
                data: _vm.productList,
                "edit-rules": _vm.validRules,
                "edit-config": {
                  trigger: "click",
                  mode: "row",
                  showIcon: false,
                  immediate: true,
                },
              },
            },
            [
              _c("vxe-table-column", {
                attrs: {
                  field: "productAndLevelCode",
                  title: "产品",
                  "edit-render": !this.disabled && {
                    name: "ElSelect",
                    options: this.productOptionList,
                    props: {
                      placeholder: "请选择产品",
                      transfer: true,
                      filterable: true,
                      remote: true,
                      reserveKeyword: true,
                      remoteMethod: this.getProductList,
                      loading: this.loading,
                    },
                    events: {
                      change: function (ref) {
                        var row = ref.row

                        this$1.changeRow(row, "productAndLevelCode")
                      },
                    },
                  },
                },
              }),
              _c("vxe-table-column", {
                attrs: {
                  field: "shareRatio",
                  title: "产品比例（%）",
                  required: "",
                  "edit-render": !this.disabled && {
                    name: "$input",
                    props: { placeholder: "请输入产品比例", type: "number" },
                    events: {
                      change: function (ref) {
                        var row = ref.row

                        this$1.changeRow(row, "shareRatio")
                      },
                    },
                  },
                },
              }),
              _c("vxe-table-column", {
                attrs: {
                  field: "remarks",
                  title: "备注",
                  "edit-render": !this.disabled && {
                    name: "$input",
                    props: { placeholder: "请输入备注" },
                    events: {
                      change: function (ref) {
                        var row = ref.row

                        this$1.changeRow(row, "remarks")
                      },
                    },
                  },
                },
              }),
              !this.disabled
                ? _c("vxe-table-column", {
                    attrs: { title: "操作", width: "100", "show-overflow": "" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (ref) {
                            var row = ref.row
                            return [
                              _c("vxe-button", {
                                attrs: { type: "text", icon: "el-icon-delete" },
                                on: {
                                  click: function ($event) {
                                    return _vm.removeEvent(row)
                                  },
                                },
                              }),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      3988826367
                    ),
                  })
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }