<template>
  <div class="edit-table">
    <el-tabs v-model="tabName" type="border-card" :before-leave="switchTab" @tab-remove="removeTab">
      <el-tab-pane key="add" name="add" v-if="!disabled">
        <span slot="label"><i class="el-icon-plus"></i> 新增</span>
      </el-tab-pane>

      <el-tab-pane
        :closable="!disabled"
        v-for="item in categoriesMapKeys"
        :key="item[1].value"
        :label="item[1].label"
        :name="item[1].value"
      >
        <relation-fine
          actType="project"
          :disabled="disabled"
          :categoriesCode="item[1].value"
          :categoriesName="item[1].label"
          :beginDate="beginDate"
          :endDate="endDate"
          :isFileUpload="false"
          :functionCode="item[1].functionCode"
          :controlIds="controlIds"
          v-model="item[1].fine"
          @change="onChangeFineValue"
        >
        </relation-fine>
        <h3 v-if="item[1].showProduct">分摊信息</h3>
        <relation-product
          :categoriesCode="item[1].value"
          :categoriesName="item[1].label"
          v-model="item[1].product"
          :disabled="disabled"
          :show="item[1].showProduct"
          @change="onChangeProductValue"
        >
        </relation-product>
      </el-tab-pane>
    </el-tabs>
    <!-- 弹框 -->
    <SelectConfig ref="selectConfig" @onGetSelect="onGetSelect" />
  </div>
</template>

<script>
import request from '../../../../../../utils/request';
import RelationFine from './fine/table/table.vue';
import RelationProduct from './product/relation_product_form.vue';
import SelectConfig from '../../../../../../components/select_org_cus_ter/select_config.vue';

export default {
  name: 'ContainOrg',
  props: {
    value: Object,
    disabled: Boolean,
    time: Object,
    controlIds: Array,
    saveRef: Function,
    default: () => ({
      controlIds: [],
      time: {},
    }),
  },
  data() {
    return {
      selectRow: null,
      loading: false,
      beginDate: '',
      endDate: '',
      categoriesMapKeys: new Map(), // tab key信息
      tabName: '',
    };
  },
  components: {
    RelationFine,
    RelationProduct,
    SelectConfig,
  },
  watch: {
    value() {
      this.categoriesMapKeys = new Map([...this.value.data]);
      this.setDefaultTab();
    },
    time(n) {
      if (n) {
        this.beginDate = n.beginDate;
        this.endDate = n.endDate;
      }
    },
  },
  mounted() {
    if (this.saveRef) {
      this.saveRef(this);
    }
  },
  methods: {
    // 保留已选大类
    async reset(ids) {
      if (ids && ids.length) {
        const data = await this.getCateGoriesData(ids);
        const list = this.categoriesMapKeys;
        let newCodes = [];
        if (data && data.length) {
          // 获取最新code
          newCodes = data.map((item) => item.categoriesCode);

          // 删除已经移除的code
          for (const key of list.keys()) {
            if (!newCodes.includes(key)) {
              list.delete(key);
            }
          }
        }

        this.categoriesMapKeys = list;
        this.setDefaultTab();
      } else {
        this.clear();
      }
    },
    // 清空所有大类
    clear() {
      this.categoriesMapKeys.clear();
    },
    removeTab(activeName) {
      this.setDefaultTab(activeName);
      const tmp = new Map([...this.categoriesMapKeys]);
      tmp.delete(activeName);
      this.categoriesMapKeys = tmp;
    },
    switchTab(activeName) {
      if (activeName === 'add') {
        this.addTab();
        return false;
      }
      this.tabName = activeName;
    },
    onChangeProductValue() {
      this.value.data = this.categoriesMapKeys;
    },
    onChangeFineValue(data) {
      let hasIsShareToProduct = false;
      const code = this.tabName;
      if (data && data.length) {
        data.forEach((item) => {
          if (item.isShareToProduct === 'Y') {
            hasIsShareToProduct = true;
          }
        });
      }
      // 更新分摊信息的状态
      const cg = new Map([...this.categoriesMapKeys]);
      if (code && cg.has(code)) {
        const obj = cg.get(code);
        if (hasIsShareToProduct) {
          cg.set(code, {
            ...obj,
            showProduct: hasIsShareToProduct,
          });
        } else {
          cg.set(code, {
            ...obj,
            showProduct: hasIsShareToProduct,
            product: {
              data: [],
            },
          });
        }
      }

      this.categoriesMapKeys = cg;
      this.value.data = cg;
    },
    addTab() {
      const { beginDate, endDate } = this.time;
      if (this.time && (!beginDate || !endDate)) {
        this.$message.error('请先选择活动时间！');
      } else if (!this.controlIds || (this.controlIds && this.controlIds.length === 0)) {
        this.$message.error('请先选择预算费用！');
      } else {
        const _data = Array.from(this.categoriesMapKeys.keys()).map((v) => ({
          categoriesCode: v,
        }));
        const params = {
          functionCode: 'tpm_project_categories_list',
          data: _data,
          idKey: 'categoriesCode',
          paramData: {
            enableStatus: '009',
            controlIds: this.controlIds,
            actType: 'project',
          },
        };

        this.$refs.selectConfig.openSelectModal(params);
      }
    },
    onGetSelect(data) {
      const list = this.categoriesMapKeys;
      let newCodes = [];
      if (data && data.length) {
        // 获取最新code
        newCodes = data.map((item) => item.categoriesCode);
        // 添加最新code
        data.forEach((item) => {
          if (!list.has(item.categoriesCode)) {
            list.set(item.categoriesCode, {
              label: item.categoriesName,
              value: item.categoriesCode,
              functionCode: item.formCode,
              fine: {
                data: [],
              },
              showProduct: false,
              product: {
                data: [],
              },
            });
          }
        });
        // 删除已经移除的code
        for (const key of list.keys()) {
          if (!newCodes.includes(key)) {
            list.delete(key);
          }
        }
      }

      this.categoriesMapKeys = list;
      this.setDefaultTab();
    },
    setDefaultTab(key) {
      // 设置默认显示面板
      if (this.categoriesMapKeys.size > 0) {
        const keys = Array.from(this.categoriesMapKeys.keys());
        if (key) {
          const index = keys.findIndex((item) => item === key);
          if (index > -1) {
            let left = null;
            if (index === 0) {
              left = this.categoriesMapKeys.get(keys[index + 1]);
              this.tabName = left && left.value;
            } else {
              left = this.categoriesMapKeys.get(keys[index - 1]);
              this.tabName = left && left.value;
            }
          }
        } else {
          const first = this.categoriesMapKeys.get(keys[0]);
          this.tabName = first.value;
        }
      }
      // 设置分摊的显隐
      if (this.categoriesMapKeys.has(this.tabName)) {
        const {
          fine: { data },
        } = this.categoriesMapKeys.get(this.tabName);
        this.onChangeFineValue(data);
      }
    },
    async getCateGoriesData(ids) {
      const res = await request.post('/tpm/tpmActController/getCategoriesByBudget', {
        enableStatus: '009',
        controlIds: ids,
        actType: 'project',
      });
      return res.result || [];
    },
    async getSelectData(url, params, optionsKey) {
      const res = await request.post(url, params);
      const list = res.result || [];
      if (res.success) {
        list.forEach((item, index) => {
          list[index].label = item[optionsKey.label];
          list[index].value = item[optionsKey.value];
        });
      }
      return list;
    },
  },
};
</script>

<style lang="less" scoped>
.edit-table {
  padding-left: 50px;
}
</style>
