<!--
 * @Author: chenlei
 * @Date: 2020-12-09 10:40:24
 * @LastEditors: chenlei
 * @LastEditTime: 2020-12-21 15:26:46
 * @Description:
-->
<template>
  <a-modal
    :title="modalConfig.title"
    :visible="modalConfig.visible"
    :width="modalConfig.width||'1000px'"
    :destroyOnClose="true"
    :maskClosable='false'
    @cancel="close"
  >
      <el-scrollbar
        wrap-class="content-scrollbar"
        class="dialog-style"
        :native="false"
        view-class="content-container"
        :noresize="false"
        tag="div"
      >
        <upload-table @onGetFileList="onGetFileList" ref="fileTable" :disabled="disabled"></upload-table>
      </el-scrollbar>
      <div slot="footer" class="dialog-footer">
        <el-button @click="close">关闭</el-button>
      </div>
  </a-modal>
</template>

<script>

import UploadTable from './upload_table.vue';

export default {
  components: {
    'upload-table': UploadTable,
  },
  props: {
    value: Array,
    disabled: Boolean,
  },
  watch: {
    value(n) {
      this.fileList = n;
    },
  },
  data() {
    return {
      fileList: [],
      modalConfig: {
        title: '上传附件',
        visible: false,
      },
    };
  },
  methods: {
    onGetFileList(data) {
      this.$emit('onGetFileList', data);
    },
    // 打开弹窗
    openModal(data) {
      this.modalConfig = {
        ...this.modalConfig,
        ...{ visible: true },
      };
      this.$nextTick(() => {
        if (data) {
          this.$refs.fileTable.setTableList(data);
        }
      });
    },
    // 关闭弹窗
    close() {
      this.modalConfig = { ...this.modalConfig, visible: false };
    },
  },
};
</script>

<style lang="less" scoped>
.dialog-style {
  height: 60vh;
  padding-bottom: 50px;
}
/deep/.content-container{
  height: 100%;
}
.content-scrollbar {
  overflow-x: hidden;
}
</style>
